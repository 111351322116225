<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="STATES"
          :breadcrumb="[
            {
              label: 'Masters',
             
            },
            { label: 'States List' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card "
              style=" height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <div class="col-10">
                    <h4><b style="color: #00364f; font-weight: bolder">State List</b></h4>
                  </div>
                  <div class="col-2">
                    <!-- <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      aria-label="Default select example"
                      @change="states()"
                    >
                      <option value="5" selected>5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select> -->
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top:5px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th style="width: 5%">+</th>
                          <th style="width: 65%">Name</th>
                          <th style="width: 10%" class="text-truncate">Short Name</th>
                          <th style="width: 10%"  class="text-truncate">State Code</th>
                          <th style="width: 10%">Status</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(state, index) in allstates"
                            :key="index"
                          >
                            <td>{{ index + 1 }}</td>

                            <td  class="text-truncate">{{ state.name }}</td>
                            <td>{{ state.shortName }}</td>
                            <td>{{ state.stateCode }}</td>

                            <td>
                              <p
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ state.status }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <!-- <div class="card-footer">
                <div class="row">
                  <div class="col-8">
                    <ul class="pagination">
                      <li class="page-item" @click="states(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="states(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 3"
                        @click="states(pagination.current_page - 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 2"
                        @click="states(pagination.current_page - 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 1"
                        @click="states(pagination.current_page - 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li
                        class="active page-item"
                        @click="states(pagination.current_page)"
                      >
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 4"
                        @click="states(pagination.current_page + 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 5"
                        @click="states(pagination.current_page + 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 6"
                        @click="states(pagination.current_page + 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li> -->
              <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="states( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
              <!-- <li class="page-item"><a class="page-link" href="#">...</a></li> -->

              <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="states( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
              <!-- <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 3"
                        @click="states(pagination.last_page - 3)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 2"
                        @click="states(pagination.last_page - 2)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 1"
                        @click="states(pagination.last_page - 1)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="states(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="states(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul> -->
              <!-- </div>
                  <div class="col-4 text-end">
                    Showing {{ pagination.from }} to {{ pagination.to }} of
                    {{ pagination.total }} entries
                  </div> -->
              <!-- </div> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "States",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 5,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 50,

      loading: false,
      allstates: [],
    };
  },
  methods: {
    states(page = null, pg = null) {
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `superadmin/state?per_page=${this.pagination.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }
      pageUrl = `superadmin/state?all=all`;

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.allstates = res.data.data;
          // this.pagination = res.data.data;
        });
      //  .finally(() => (this.loading = false))
      // .catch((error) => {
      //   console.log(error)
      // })
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.states
    console.log(this.$store.state.superAdminPageTitles.states)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.states();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
option:hover {
  background-color: #f21000;
}

option::before,
option::after {
  background-color: #f21000;
}
</style>
